import React, { useState } from 'react';
import { TextField, Stack, InputLabel, Select, MenuItem, Button, OutlinedInput, Box, Checkbox, ListItemText, } from '@mui/material';
import Container from "react-bootstrap/Container";
import { Card} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ControlButton from '../../takepoll/ControlButton';

export default function Birthday({data, setData, buttonFunc}) {

    const qNum = 3;
    const infoLength = 6;
    const parseData = (type) => {
        if (typeof data !== 'string' || !data.includes('/')) return null;
        const [parsedDay, parsedMonth, parsedYear] = data.split('/');
    
        if (type === 'day') return parsedDay;
        if (type === 'month') return parsedMonth;
        if (type === 'year') return parsedYear;
    
        return null; 
    };
    const [day, setDay] = useState(parseData('day'));
    const [month, setMonth] = useState(parseData('month'));
    const [year, setYear] = useState(parseData('year'));

    const onBack = () => {
        buttonFunc(false);
    }
    const onNext = () => {
        buttonFunc(true);
    }
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);

    const setDataWrapper = (type, input) => {
        if (type === 'day') setDay(input);
        if (type === 'month') setMonth(input);
        if (type === 'year') setYear(input);

        if (day && month && year) {
            const formattedDay = type === 'day' ? input : day;
            const formattedMonth = type === 'month' ? input : month;
            const formattedYear = type === 'year' ? input : year;
            let newData = `${String(formattedDay).padStart(2, '0')}/${String(formattedMonth).padStart(2, '0')}/${formattedYear}`
            console.log(newData)
            setData(newData);
        }
    };

    
    
  
  return (
    <Stack direction="column" spacing={2} sx={{margin: 2, padding: 2}}>
        <h1 className='red'>Complete Your Profile</h1>
        <div style={{padding: "100px"}}>
            <div style={{ margin: '20px', padding: '20px', justifyContent: 'center'}}>
                <h2 className="black" style={{'text-align': 'center'}}> What is your Birthday?</h2>
                <div
                    style={{
                        height: '45px',
                        width: '100%',
                    }}
                ></div>
                <Stack
                    direction="row"
                    alignItems="center" // Align items vertically in the center
                    justifyContent="center" // Space items appropriately
                    spacing={2}
                    sx={{ width: '100%' }}
                    >
    
                <select
                    value={day}
                    onChange={(e) => setDataWrapper('day', e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px' }}
                >
                    <option value="" disabled>
                        Day
                    </option>
                    {days.map((d) => (
                        <option key={d} value={d}>
                            {d}
                        </option>
                    ))}
                </select>
                <select
                    value={month}
                    onChange={(e) => setDataWrapper('month', e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px' }}
                >
                    <option value="" disabled>
                        Month
                    </option>
                    {months.map((m, idx) => (
                        <option key={idx} value={idx + 1}>
                            {m}
                        </option>
                    ))}
                </select>
                <select
                    value={year}
                    onChange={(e) => setDataWrapper('year', e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px' }}
                >
                    <option value="" disabled>
                        Year
                    </option>
                    {years.map((y) => (
                        <option key={y} value={y}>
                            {y}
                        </option>
                    ))}
                </select>
                </Stack>
            </div>
            <div
                style={{
                    height: '100px',
                    width: '100%',
                }}
            ></div>
            <Stack
                direction="row"
                alignItems="center" // Align items vertically in the center
                justifyContent="space-between" // Space items appropriately
                spacing={2}
                sx={{ width: '100%' }}
                >
                <div
                    className="progress-bar-container"
                    style={{
                        flex: '1', // Take up available space
                        textAlign: 'left', // Ensure content aligns to the left
                    }}
                >
                    <progress
                        value={qNum}
                        max={infoLength}
                        className="progress-bar"
                    ></progress>
                    <span className="progress-text">
                        {`${qNum}/${infoLength}`}
                    </span>
                </div>
                <div
                    className="control-buttons-container"
                    style={{
                        flex: '1', // Take up available space
                        display: 'flex', // Use flexbox for buttons
                        justifyContent: 'center', // Center buttons horizontally
                        alignItems: 'center', // Center buttons vertically
                    }}
                >
                    <ControlButton
                        handleClick={onBack}
                        isDisabled={qNum <= 1 }
                        buttonText="BACK"
                    />
                    <ControlButton
                        handleClick={onNext}
                        isDisabled={(data == null)}
                        buttonText="NEXT"
                    />
                </div>
                <div
                    className="invisible-placeholder"
                    style={{
                        flex: '1', // Occupy the right third of the row
                    }}
                ></div>
            </Stack>
        </div>
    </Stack>
  );
}