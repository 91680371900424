import React, { useState } from 'react';
import { TextField, Stack, InputLabel, Select, MenuItem, Button, OutlinedInput, Box, Checkbox, ListItemText, } from '@mui/material';
import Container from "react-bootstrap/Container";
import { Card} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { getData } from 'country-list';
import ControlButton from '../../takepoll/ControlButton';

export default function Nationality({data, setData, buttonFunc}) {

    const countries = getData().map(x => x.name); 
    const qNum = 6;
    const infoLength = 6;

    const onBack = () => {
        buttonFunc(false);
    }
    const onNext = () => {
        buttonFunc(true);
    }
  
  return (
    <Stack direction="column" spacing={2} sx={{margin: 2, padding: 2}}>
        <h1 className='red'>Complete Your Profile</h1>
        <div style={{padding: "100px"}}>
            <div style={{ margin: '20px', padding: '20px', justifyContent: 'center'}}>
                <h2 className="black" style={{'text-align': 'center'}}> What is your Nationality?</h2>
                <div
                    style={{
                        height: '45px',
                        width: '100%',
                    }}
                ></div>
                <Stack
                    direction="row"
                    alignItems="center" // Align items vertically in the center
                    justifyContent="center" // Space items appropriately
                    spacing={2}
                    sx={{ width: '100%' }}
                    >
                    <select
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                        style={{
                            padding: '10px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            cursor: 'pointer',
                            width: '200px',
                        }}
                    >
                        <option value="" disabled>
                            Select a Country
                        </option>
                        {countries.map((country) => (
                            <option key={country} value={country}>
                                {country}
                            </option>
                        ))}
                    </select>
                </Stack>
            </div>
            <div
                style={{
                    height: '100px',
                    width: '100%',
                }}
            ></div>
            <Stack
                direction="row"
                alignItems="center" // Align items vertically in the center
                justifyContent="space-between" // Space items appropriately
                spacing={2}
                sx={{ width: '100%' }}
                >
                <div
                    className="progress-bar-container"
                    style={{
                        flex: '1', // Take up available space
                        textAlign: 'left', // Ensure content aligns to the left
                    }}
                >
                    <progress
                        value={qNum}
                        max={infoLength}
                        className="progress-bar"
                    ></progress>
                    <span className="progress-text">
                        {`${qNum}/${infoLength}`}
                    </span>
                </div>
                <div
                    className="control-buttons-container"
                    style={{
                        flex: '1', // Take up available space
                        display: 'flex', // Use flexbox for buttons
                        justifyContent: 'center', // Center buttons horizontally
                        alignItems: 'center', // Center buttons vertically
                    }}
                >
                    <ControlButton
                        handleClick={onBack}
                        isDisabled={qNum <= 1 }
                        buttonText="BACK"
                    />
                    <ControlButton
                        handleClick={onNext}
                        isDisabled={(data == null)}
                        buttonText="NEXT"
                    />
                </div>
                <div
                    className="invisible-placeholder"
                    style={{
                        flex: '1', // Occupy the right third of the row
                    }}
                ></div>
            </Stack>
        </div>
    </Stack>
  );
}