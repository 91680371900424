import React from 'react';
import '../../css/about.css';
import CircleAnimation from '../animations/circleanimation';

export function About() {
    return (
      <div className="about-container">
        <div className="about-content-wrapper">
          {/* Hero Section with Who We Are */}
          <div className="who-we-are-container">
            <div className="who-we-are-content">
              <h1 className="gradient-title">
                Who we are.
              </h1>
              <p className="description-text">
                We're a group of international collaborating geneticists and researchers who believe the info within your DNA and mind should be easy to access.
              </p>
            </div>
  
            <div className="vessel-circles-container">
              <div style={{
                position: 'absolute',
                right: '-100px',
                top: '-170px'
              }}>
                <CircleAnimation width={719} height={719} />
              </div>
            </div>
          </div>
  
          {/* Feature Section */}
          <div className="feature-section">
            <h2 style={{
                marginTop: "180px",
                marginBottom: "60px",
                fontSize: "36px",
                fontWeight: "700",
                lineHeight: "39.6px"
            }}>Why we started this project.</h2>
            
            <div className="steps-container" style={{ gap: '120px' }}>
                <div className="step-card" style={{ minHeight: '362px' }}>
                    <div className="step-header">
                        <div className="step-number">1</div>
                        <h3>Identified a Problem.</h3>
                    </div>
                    <p>
                        As researchers, we saw a clear divide in genetic analysis tools. The most accurate platforms required programming expertise and were designed for scientists, while consumer-friendly services often sacrificed scientific rigor for simplicity.
                        <br/><br/>
                        No tool brought research-grade analysis to the everyday person.
                    </p>
                </div>

                <div className="step-card" style={{ minHeight: '362px' }}>
                    <div className="step-header">
                        <div className="step-number">2</div>
                        <h3>Created a Solution</h3>
                    </div>
                    <p>
                        We believed high-quality scientific analysis shouldn't be limited to research laboratories.
                        <br/><br/>
                        So we built a platform that bridges this gap, combining sophisticated genetic analysis with validated psychological assessments - all in an interface anyone can use.
                    </p>
                </div>

                <div className="step-card" style={{ minHeight: '362px' }}>
                    <div className="step-header">
                        <div className="step-number">3</div>
                        <h3>Making it Happen</h3>
                    </div>
                    <p>
                        Today, our platform brings professional-grade analysis to your browser.
                        <br/><br/>
                        Users can explore their genetic traits and take established psychological tests used in academic research, all through a free, easy-to-use tool that maintains complete transparency about our methods.
                    </p>
                </div>
            </div>

            <div className="section-base">
                <img
                    src="/about_woman.jpeg"
                    alt="Woman reading by the water"
                    style={{
                        width: '438px',
                        height: '242px',
                        borderRadius: '60px',
                        objectFit: 'cover'
                    }}
                />
                <div className="contribution-content">
                    <h2 className="section-title">Your contribution to science.</h2>
                    <p className="section-text">
                        Science advances through collaboration. When you use our platform, your data helps researchers better understand human genetics and psychology. In return, you get free, personalized insights into yourself - making you both a contributor to and beneficiary of scientific progress.
                    </p>
                </div>
            </div>
  
            {/* Privacy Section */}
            <div className="section-base">
              <div style={{
                width: '438px',
                height: '242px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <img
                  src="/about_shield.svg"
                  alt="Privacy Shield"
                  style={{
                    width: '220px',
                    height: '220px'
                  }}
                />
              </div>
              <div className="contribution-content">
                <h2 className="section-title">
                  Worrying about privacy?<br/>
                  You don't need to.
                </h2>
                <p className="section-text">
                  As researchers and scientists we have a strong understanding of protecting peoples data. We're used to anonymizing data, especially genetic data. Our tool is built in full HIPPA compliance, whether thats data storage or how we use your data.
                </p>
                <p className="section-text">
                  Your data is stored fully encrypted and anonymized from the second you upload it. No one will be able to link your data back to you.
                </p>
                <div className="privacy-features">
                  <div className="privacy-feature">
                    <img 
                      src="/about_icon.svg" 
                      alt="Check" 
                      style={{
                        width: '24px',
                        height: '24px'
                      }}
                    />
                    <span className="feature-text">Encrypted</span>
                  </div>
                  <div className="privacy-feature">
                    <img 
                      src="/about_icon.svg" 
                      alt="Check" 
                      style={{
                        width: '24px',
                        height: '24px'
                      }}
                    />
                    <span className="feature-text">Anonymized</span>
                  </div>
                  <div className="privacy-feature">
                    <img 
                      src="/about_icon.svg" 
                      alt="Check" 
                      style={{
                        width: '24px',
                        height: '24px'
                      }}
                    />
                    <span className="feature-text">Secure</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}