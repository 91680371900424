import {React, useRef, useState} from 'react';
import Swal from "sweetalert2";
import {loginApi, signupApi} from "../../api/apiAuth";
import {Link} from "react-router-dom";
import AgreementPrompt from "./AgreementPrompt";
import '../../css/Signup.css'

export default function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassord] = useState('');
    const [name, setName] = useState('');
    const [success, setSuccess] = useState('');
    const agreementBox = useRef(null);
    const [loading, setLoading] = useState(false);

    const signUp = async () => {
        if (!agreementBox.current.checked) {
            Swal.fire("Error", "Please agree to the terms and conditions!", "error");
            return;
        }
        if (password !== confirmPassword) {
            Swal.fire("Error", "Your passwords do not match!", "error");
            return;
        }
        if (password.length < 5) {
            Swal.fire("Error", "Your password must be longer than 5 characters.", "error");
            return;
        }
        setLoading(true);
        const data = {
            name: name,
            email: email,
            password: password,
        };
        try {
            await signupApi(data);
            Swal.fire("Signup succeeded!", "A verification email has been sent. You must verify before logging in.", "success");
            window.location = '/verify';
        } catch (e) {
            console.log(e);
            Swal.fire("Error", `Login Failed: ${e.response.data.message}`, "error");
        } finally {
            setLoading(false);
        }
        
    };

    return (
        <div style={{ display: "flex", gap: "4rem", justifyContent: "center", padding: "50px"}} >
                    <div style={{width: "365px"}}>
                        <h2 className="red">Sign Up</h2>
                        <br></br>
                        <h2 className="black" style={{fontSize: "20px"}}>In a moment you’ll have access to free DNA trait analysis and dozens of free psychological tests.</h2>
                        <br></br>
                        <div>
                            Already have an account? <Link to="/signin">Log In</Link>
                        </div>
                    </div>
                    <div >
                        <div className="Auth-form-container">
                            <form className="Auth-form">
                                <div className="Auth-form-content">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control signup-input"
                                            placeholder="Name"
                                            id="firstName"
                                            name="firstName"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control signup-input"
                                            placeholder="Email"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control signup-input"
                                            placeholder="Password"
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control signup-input"
                                            placeholder="Confirm Password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassord(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <AgreementPrompt checkboxRef={agreementBox} />
                                    <div className="form-group mt-3">
                                        <button
                                            type="submit"
                                            className="btn signup-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                void signUp();
                                            }}
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                'Sign Up'
                                            )}
                                        </button>
                                    </div>
                                    {success && <p className="success-message">{success}</p>}
                                </div>
                            </form>
                        </div>
                    </div>

        </div>
    );
    
}