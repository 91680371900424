import {Checkbox, FormControlLabel} from "@mui/material";
import DOMPurify from "dompurify";
import { useEffect } from "react";

export default function ChoiceCheckbox({choice, selections, setSelections, allAnswers, q_id}) {

    useEffect(() => {
        const answerObj = allAnswers.find((answer) => answer.q_id === q_id);
        if (answerObj && answerObj.reply) {
        const trueReplies = answerObj.reply.filter((r) => r.value === true);
        const ids = trueReplies.map((r) => r.ch_id);
        setSelections((prev) => {
            const newSelections = new Set(prev);
            ids.forEach((id) => newSelections.add(id));
            return Array.from(newSelections);
        });
        }
    }, [allAnswers, q_id, setSelections]);

    const safeHTML = DOMPurify.sanitize(choice.text);
    return (
        <FormControlLabel key={choice.id}
                          label={<span className="choice-content" dangerouslySetInnerHTML={{__html: safeHTML}}/>}
                          control={
                              <Checkbox checked={selections.includes(choice.id)}
                                        onChange={() => {
                                            setSelections(prev => {
                                                if (prev.includes(choice.id)) {
                                                    return prev.filter(id => id !== choice.id);
                                                } else {
                                                    return [...prev, choice.id];
                                                }
                                            });
                                        }}/>
                          }/>
    )
}