import Container from "react-bootstrap/Container";
import {getToken, handleAuthenticationError, isAdmin} from "../../userAuth";
import React, {useEffect, useState, useCallback} from "react";
import { useSearchParams } from "react-router-dom";
import {getPollsByAuthorApi} from "../../api/apiAnon";
import {getAllCompletedPollsApi} from "../../api/apiUser";
import PollCard from "../common/PollCard";
import {Row} from "react-bootstrap";
import "../../css/mytests.css";
import ViewResult from "../viewresults/ViewResult";
import { ClipLoader } from "react-spinners";

export default function MyTests() {
    const [searchParams] = useSearchParams();
    const short = searchParams.get("short");
    const long = searchParams.get("long");
    const details = searchParams.get("details");
    console.log(short, long, details);
    const [polls, setPolls] = useState(null);
    const [completedPolls, setCompletedPolls] = useState(null);
    const [selectedPoll, setSelectedPoll] = useState(null);
    const [short_title, setShortTitle] = useState(short);
    const [title, setTitle] = useState(long);
    const [description, setDescription] = useState(details);
    const [loading, setLoading] = useState(true);
    const CACHE_KEY = 'testResults'

    useEffect(() => {
        const fetchPolls = async () => {
            try {
                const cachedData = localStorage.getItem(CACHE_KEY);
                if (cachedData) {
                    console.log('Using cached data');
                    let cached_polls = JSON.parse(cachedData);
                    setCompletedPolls(cached_polls);
                    setLoading(false);
                }

                const response = await getAllCompletedPollsApi({token: getToken()});
                let filtered = response?.data?.responses?.filter(response => response != null);
                localStorage.setItem(CACHE_KEY, JSON.stringify(filtered));
                setCompletedPolls(filtered);
                setLoading(false);
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls', error);
            }

        };

        void fetchPolls();
    }, []);

    const handlePollClick =  useCallback((poll) => {
        setSelectedPoll(poll)
        setTitle(poll.title)
        setShortTitle(poll.short_title)
        setDescription(poll.details)
    }, [])

    return (
        <div className="mytest_maindiv">
            {loading ? (
                <div className="loading-screen">
                    <ClipLoader color="#36d7b7" size={50} />
                </div>
            ) : (
                <>
                    <div className="mytest_emptyol" style={{ width: "17%", height: "100%", backgroundColor: "transparent" }}>
                    </div>
                    <div className="mytest_col">
                        <div className="mytest_infocard">  
                            {completedPolls && (
                                <>
                                    <h2>Completed Tests</h2>
                                    <p>Manage each of all the tests you have taken by clicking on one of them below.</p>
                                    <div>
                                        {completedPolls.map((completedPoll) => (
                                            <>   
                                                <div
                                                    key={completedPoll.title}
                                                    className={
                                                        short_title === completedPoll.short_title
                                                            ? "mytest_pollcard_clicked"
                                                            : "mytest_pollcard"
                                                    }
                                                    onClick={() => handlePollClick(completedPoll)}
                                                >
                                                    {completedPoll.title}
                                                </div>
                                                <div 
                                                    key={completedPoll.title + '2'}
                                                    style={{ 
                                                        width: "17%", 
                                                        height: "20px", 
                                                        backgroundColor: "transparent", 
                                                        visibility: "hidden" 
                                                    }}>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    {completedPolls.length === 0 && <em>You have not completed any tests.</em>}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="mytest_emptyol" style={{ width: "1%", height: "100%", backgroundColor: "transparent" }}>
                    </div>
                    { short_title && title && description && 
                    <div>
                        <ViewResult short_title={short_title} title={title} description={description} />
                    </div>
                    }
                    {isAdmin() && polls && (
                        <>
                            <h3>ADMIN - Authored Polls</h3>
                            <Row className="border-top p-4 align-items-center justify-content-center center">
                                {polls.map((poll) => <PollCard poll={poll} />)}
                                {!polls.length && <p className="bg-body-tertiary">No Authored Polls</p>}
                            </Row>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
