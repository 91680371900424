import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getToken } from '../../userAuth';
import { uploadPfp } from '../../api/apiUser';
import Swal from 'sweetalert2';

function ProfileUpload() {
  const [imagePreview, setImagePreview] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        setImagePreview(event.target.result); // base64 string
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const uploadPfp_ = async () => {
    const data = {
        token: getToken(),
        pfp: imagePreview
    }
    console.log(data)
    try {
        const res = await uploadPfp(data);
        Swal.fire({
            icon: 'success',
            title: "Upload Success!",
            showConfirmButton: true,
            text: 'Your image was uploaded!',
        });
        localStorage.setItem('pfp', imagePreview);
    } catch (e) {
        console.error(e)
        Swal.fire({
            icon: 'error',
            title: "Upload Failed",
            showConfirmButton: true,
            text: 'Upload failed, please try again.',
        });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <h2 className="black">Upload Profile Picture</h2>
      {!imagePreview &&
      <div
        {...getRootProps()}
        className="upload-box"
      >
        <img src="/upload.svg" alt="Upload" className="upload-icon" />
        <input {...getInputProps()} />
        {isDragActive
          ? <h3>Drop the file here...</h3>
          : <h3>Drag &amp; drop an image here, or click to browse</h3>
        }
      </div>
        }

      {imagePreview && (
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <img
            src={imagePreview}
            alt="Preview"
            style={{
                marginTop: '1rem',
                maxWidth: '300px',
                borderRadius: '5px'
            }}
            />
            
            <div style={{display: "flex", flexDirection: "row", justifyContent:"center", gap:"12px"}}>
                <button className="light-button" onClick={uploadPfp_}>Upload</button>
                <button className="light-button" onClick={() => {setImagePreview(null)}}>Cancel</button>
            </div>
        </div>
      )}
    </>
  );
}

export default ProfileUpload;
