import React, { useState } from "react";
import { backendUri } from "../../api/config";
import { removePollByShortTitleApi } from "../../api/apiAdmin";
import "../../css/PollCard.css"
import "../../css/global.css"

export default function PollCard({pollcard, navigateFunc}) {
  const [hover, setHover] = useState(false);

  async function navigateToPoll() {
    await navigateFunc(pollcard)
  }

  if (!pollcard.thumbnail) pollcard.thumbnail = 'logo.png';

  return (
    <div
      onClick={navigateToPoll}
      className={`poll-card ${hover ? 'poll-card-hover' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
        <img
          src={`${backendUri}/images/${pollcard.thumbnail}`}
          alt={pollcard.title}
          className="poll-card-image"
        />
        <h4 className='poll-card-title'>{pollcard.title || 'Test Name'}</h4>
        <p className="poll-card-description">{pollcard.details || 'This is the description of the test.'}</p>        
    </div>
  );
}