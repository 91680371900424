import React, { useState } from 'react';
import { TextField, Stack, InputLabel, Select, MenuItem, Button, OutlinedInput, Box, Checkbox, ListItemText, } from '@mui/material';
import Container from "react-bootstrap/Container";
import { Card} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ControlButton from '../../takepoll/ControlButton';

export default function Ethnicity({data, setData, buttonFunc}) {

    const qNum = 4;
    const infoLength = 6;

    const onBack = () => {
        buttonFunc(false);
    }
    const onNext = () => {
        buttonFunc(true);
    }

    const setDataWrapper = (input) => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array. Initializing as an empty array.");
            setData([input]);
        } else {
            if (data.includes(input)){
                setData(data.filter(item => item !== input));
            }
            else {
                setData([...data, input]);
            }   
        }
    };

    const options = ['White', 'Hispanic', 'Black', 'MENA', 'Jewish', 'East Asian', 'South Asian'];

  return (
    <Stack direction="column" spacing={2} sx={{margin: 2, padding: 2}}>
        <h1 className='red'>Complete Your Profile</h1>
        <div style={{padding: "100px"}}>
            <div style={{ margin: '20px', padding: '20px', justifyContent: 'center'}}>
                <h2 className="black" style={{'text-align': 'center'}}> What is your Ethnicity?</h2>
                <div
                    style={{
                        height: '45px',
                        width: '100%',
                    }}
                ></div>
                <Stack
                    direction="row"
                    alignItems="center" // Align items vertically in the center
                    justifyContent="center" // Space items appropriately
                    spacing={2}
                    sx={{ width: '100%' }}
                    >
                    {options.map(option => 
                        (
                            <button className={`choice-button ${(data.includes(option)) ? 'selected' : ''}`}
                                onClick={() => {
                                    setDataWrapper(option);
                                }} >{option}
                            </button>
                        ))
                    }
                </Stack>
            </div>
            <div
                style={{
                    height: '100px',
                    width: '100%',
                }}
            ></div>
            <Stack
                direction="row"
                alignItems="center" // Align items vertically in the center
                justifyContent="space-between" // Space items appropriately
                spacing={2}
                sx={{ width: '100%' }}
                >
                <div
                    className="progress-bar-container"
                    style={{
                        flex: '1', // Take up available space
                        textAlign: 'left', // Ensure content aligns to the left
                    }}
                >
                    <progress
                        value={qNum}
                        max={infoLength}
                        className="progress-bar"
                    ></progress>
                    <span className="progress-text">
                        {`${qNum}/${infoLength}`}
                    </span>
                </div>
                <div
                    className="control-buttons-container"
                    style={{
                        flex: '1', // Take up available space
                        display: 'flex', // Use flexbox for buttons
                        justifyContent: 'center', // Center buttons horizontally
                        alignItems: 'center', // Center buttons vertically
                    }}
                >
                    <ControlButton
                        handleClick={onBack}
                        isDisabled={qNum <= 1 }
                        buttonText="BACK"
                    />
                    <ControlButton
                        handleClick={onNext}
                        isDisabled={(data == null)}
                        buttonText="NEXT"
                    />
                </div>
                <div
                    className="invisible-placeholder"
                    style={{
                        flex: '1', // Occupy the right third of the row
                    }}
                ></div>
            </Stack>
        </div>
    </Stack>
  );
}