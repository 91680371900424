import React from 'react';
import progressOne from '../../data/progressOne.json';
import progressTwo from '../../data/progressTwo.json';
import progressThree from '../../data/progressThree.json';

const AdminControls = ({ 
  setCurrentView, 
  setUploadState, 
  setResults, 
  setCurrentStage, 
  setStageDescription 
}) => {
  const handleUploadState = () => {
    const { progress } = progressOne;
    setCurrentView('upload');
    setUploadState(false);
  };

  const handleProcessingState = () => {
    const { progress } = progressTwo;
    setUploadState(true);
    setCurrentView('processing');
    setCurrentStage(progress.mappedStage);
    setStageDescription(progress.description);
  };

  const handleResultsState = () => {
    const { progress, results } = progressThree;
    setUploadState(true);
    setResults(results.results);
    setCurrentView('results');
  };

  return (
    <div style={{ display: 'flex', gap: '20px', padding: '20px', width: 'fit-content', margin: '0 auto'}} >
      <button
        onClick={handleUploadState}
        className="btn btn-default"
      >
        Mock Upload
      </button>
      <button
        onClick={handleProcessingState}
        className="btn btn-default"
      >
        Mock Processing
      </button>
      <button
        onClick={handleResultsState}
        className="btn btn-default"
      >
        Mock Results
      </button>
    </div>
  );
};

export default AdminControls;