import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {forceToken, getNextCatApi, submitPollResponseByUserApi} from '../../api/apiUser';
import PollInteractionPanel from "./PollInteractionPanel";
import Container from "react-bootstrap/Container";
import {getPollByShortTitleApi, getTimeApi} from "../../api/apiAnon";
import {getToken, handleAuthenticationError, isAdmin} from "../../userAuth";
import CurrentScoreChart from "../viewresults/CurrentScoreChart";
import {Checkbox, FormControlLabel, FormGroup, Paper, Typography} from '@mui/material';


export default function TakePoll() {

    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const {short_title} = useParams();

    const [timeLeft, setTimeLeft] = useState(-1); // Set the initial timer value in seconds

    useEffect(() => {
        if (timeLeft <= 0) return; // Stop the timer when it reaches zero

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(timer);
    }, [timeLeft]);
    const handleTimeOut = () => {
        alert("Time's up!");
        if (poll.answers.length > 0) submitClick(poll.answers, poll.scores.score);
        else submitClick([ {q_id: `${short_title}q0`, reply: 'nothing'} ], poll.scores.score);
    };
    useEffect(() => {
        if (timeLeft === 0) {
            handleTimeOut();
        }
    }, [timeLeft]);

    const [poll, setPoll] = useState({
        details: null,
        question: null,
        isCat: false,
        qIdx: 0,
        questions: null,
        answers: [],
        scores: {score: 0, mean: 0, std: 1},
        dimensions: ['Score'],
    });

    const [potential, setPotential] = useState(0);

    function maybeArrayAdd(x, y) {
        if (Array.isArray(x)) {
            return x.map((v, idx) => v + (Array.isArray(y) ? (y[idx] || 0) : y));
        } else {
            return x + y;
        }
    }
    

    async function getNextCatQuestion(answer) {
        try {
            const data = {
                answer: answer,
                token: getToken(),
                short_title: short_title,
            };
            const response = await getNextCatApi(data);
            if (response.data.message === 'STOP') {
                navigate(`/my-tests?short=${poll.details.short_title}&long=${poll.details.title}&details=${poll.details.details}`)
            }
            setPoll(prevState => ({
                ...prevState,
                scores: {...prevState.scores, ...response.data.scores},
                question: response.data['new_question'],
                qIdx: response.data.qIdx,
                raw: response.data.raw,
            }));
        } catch (error) {
            handleAuthenticationError(error);
            console.error('Error fetching polls:', error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const data = {
                    token: getToken(),
                };
                const pollTime = await getTimeApi(short_title);
                setTimeLeft(pollTime.data);
                const t = await forceToken(data);
                const response = await getPollByShortTitleApi(short_title);
                setPoll(prevState => ({
                    ...prevState,
                    details: {
                        title: response.data.title,
                        details: response.data.details,
                        author: response.data.author,
                        short_title: response.data.short_title,
                    },
                    isCat: response.data.cat_mode,
                    questions: response.data.questions,
                    question: response.data.questions[0],
                    scoreSum: 0,
                    dimensions: response.data.dimensions,
                }));
                let qs = response.data.questions; //array of q objs. each q has q.choices which is an array with q.choices[idx].score
                let maxScores = [];
                if (Array.isArray(qs[0].choices[0].score))
                {
                    qs.forEach(q => {
                        let max = new Array(qs[0].choices[0].score.length).fill(0);
                        q.choices.forEach(c => {
                            c.score.forEach((s, idx) => {
                                if (s > max[idx])
                                {
                                    max[idx] = s;
                                }
                            })
                        });
                        maxScores.push(max);
                    });
                }
                else {
                    qs.forEach(q => {
                        let max = 0
                        q.choices.forEach(c => {
                            if (c.score > max)
                            {
                                max = c.score;
                            }
                        });
                        maxScores.push(max);
                    });
                }
                maxScores.forEach( (m, idx) => {
                    if (idx > 0)
                    {
                        maxScores[idx] = maybeArrayAdd(maxScores[idx-1], m);
                    }
                })
                setPotential(maxScores);
                if (response.data.cat_mode) {
                    await getNextCatQuestion('');
                }
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }

        void fetchData();
    }, []);

    const submitClick = async (currentAnswers, newScore) => {
        const answerData = currentAnswers.map(a => ({
            question_id: a.q_id,
            question_type: "text",
            answer: a.reply || a.selections,
        }));
        const data = {
            short_title: poll.details.short_title,
            results: answerData,
            score: newScore,
            token: getToken(),
        }
        try {
            await submitPollResponseByUserApi(data);
            navigate(`/my-tests?short=${poll.details.short_title}&long=${poll.details.title}&details=${poll.details.details}`);
        } catch (error) {
            console.log(error);
            handleAuthenticationError(error);
            alert(error.response.data.message);
            window.location.reload();
        }
    }

    const scoreHelper = (scorer, reply) => {
        //scorer is dict, answerchoice: 1 or 0
        // reply is either a choices string, or an array of dicts with ch_name (str) and value (1 or 0, selected or not).
        if (reply === null)
        {
            return 0;
        }
        if (!Array.isArray(reply))
        {
            return scorer[reply];
        }
        // now we know it's an array. Only give a 1 if all the right answers are selected. All 1s in scorer must be selected and no 0s can be selected
        // we return 0 if a 0 is selected or if a 1 is not selected
        
        for (let r of reply) {
            if (scorer[r.ch_name] === 0 && r.value === true) // check if a 0 is selected
            {
                return 0;
            }
            if (scorer[r.ch_name] === 1 && r.value === false) // check if a 1 is not selected
            {
                return 0
            }
        }
        return 1;
    };

    const newQuestion = useCallback(async (answer) => {
        setLoading(true);
        if (poll.isCat) {
            await getNextCatQuestion(answer);
        } else {
            let newScore = 0;
            let currentAnswer = '';
            for (let j = 0; j < poll.questions.length; ++j) {
                if (j === poll.qIdx) {
                    currentAnswer = answer;
                } else {
                    currentAnswer = poll.answers[j];
                    if (typeof currentAnswer?.reply === 'undefined') {
                        continue;
                    }
                }
                if (poll.questions[j].type === 'Multiple Choice') {
                    const choices = poll.questions[j].choices;
                    const scorer = choices.reduce((acc, obj) => {
                        acc[obj.text] = obj.score;
                        return acc;
                    }, {});
                    if (!Array.isArray(scorer[currentAnswer?.reply])) {   //if the dims are 1
                        newScore = scoreHelper(scorer, currentAnswer?.reply) + newScore;
                    } else {
                        if (!Array.isArray(newScore)) {
                            newScore = []
                            for (let i = 0; i < scorer[currentAnswer?.reply].length; ++i) {
                                newScore.push(0);
                            }
                        }
                        for (let i = 0; i < scorer[currentAnswer?.reply].length; ++i) {
                            newScore[i] = newScore[i] + scorer[currentAnswer.reply][i];
                        }
                    }

                }
            }
            let updatedAnswers = 0;
            if (poll.answers.length === poll.qIdx) {
                updatedAnswers = [...poll.answers, answer];
            } else {
                updatedAnswers = poll.answers.map((item, index) => {
                    if (index === poll.qIdx) {
                        return answer; // Replace the item at poll.qIdx with the new answer
                    }
                    return item; // Leave all other items unchanged
                });
            }
            const inc = poll.qIdx + 1;
            if (inc === poll.questions.length) {
                await submitClick(updatedAnswers, newScore);
            }
            setPoll(prevState => ({
                ...prevState,
                answers: updatedAnswers,
                question: poll.questions[inc],
                qIdx: inc,
                scoreSum: newScore,
            }));
            setLoading(false);
        }
    }, [poll]);

    const lastQuestion = useCallback(async () => {
        const dec = poll.qIdx - 1;
        setPoll(prevState => ({
            ...prevState,
            question: poll.questions[dec],
            qIdx: dec,
        }));
        setLoading(false);

    }, [poll]);

    const styles = {
        paragraph: {
            color: '#333', // Text color
            fontSize: '16px', // Font size
            backgroundColor: '#eef', // Background color
            padding: '8px 12px', // Padding around the text
            borderRadius: '5px', // Rounded corners
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Box shadow for depth
            maxWidth: '250px', // Maximum width of the text block
            margin: '10px 0', // Margin around the paragraph
            textAlign: 'center', // Centering the text
            border: '1px solid #ddd' // Adding a border
        },

        progressBarContainer: {
            // Container style, adjust as needed
            padding: '10px', // Adds padding around the entire container
        },
        progressBar: {
            // Making the progress bar bigger
            width: '100%', // Takes the full width of the container
            height: '20px', // Increases the height of the progress bar
            marginBottom: '10px', // Adds space below the progress bar
        },
        progressText: {
            // Style for the text showing the progress
            display: 'block', // Makes the text appear below the progress bar
            textAlign: 'center', // Centers the text
            marginTop: '10px', // Adds space above the text
        },
        scoreContainer: {
            display: 'flex',       // Enables flexbox
            justifyContent: 'center', // Centers content horizontally
            alignItems: 'center',     // Centers content vertically
            height: '100%',        // Set to the height of the container (adjust as needed)
            // Add other styling as required, e.g., padding, background color, etc.
        },
    };
    
    function formatTime(seconds) {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs > 0 ? `${hrs}h ` : ''}${mins}m ${secs}s`;
    }

    return (
        <Container>
            {!poll.isCat && poll.details && poll.question && timeLeft > 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold', fontSize: '1.5em' }}>
                        Time Remaining: {formatTime(timeLeft)}
                    </div>
                ) : null}
                {timeLeft === 0 && <div style={{ textAlign: 'center', color: 'red' }}>Time's up!</div>}
                
            <div style={{margin: "0em 2em", borderRadius: '50px'}} className="bg-body-tertiary px-4 py-1">
                {
                    poll.details && poll.question ?
                        <PollInteractionPanel
                            poll={poll}
                            pollDetails={poll.details}
                            question={poll.question}
                            answers={poll.answers}
                            newQuestion={newQuestion}
                            lastQuestion={lastQuestion}
                            isCat={poll.isCat}
                            qIdx={poll.qIdx}
                            pollLength={poll.questions.length}
                        />
                        :
                        <div>Loading...</div>
                }
                {  isAdmin() && poll.isCat && poll.details && poll.question ? <div className="mx-5 mt-4">
                        <p style={styles.paragraph}>
                            {`Score: ${poll.raw || 0} / ${poll.qIdx}`}
                        </p>
                        <hr/>
                    </div> : <></>
                }

                { isAdmin() && !poll.isCat && !Array.isArray(poll.scoreSum) ? (
                    <div style={styles.scoreContainer}>
                        <p style={styles.paragraph}>
                            Your score: {poll.scoreSum} / {potential[poll.qIdx-1] || 0}
                        </p>
                    </div>
                ) : null}

                {isLoading ? (
                    <div></div>  // Loading indicator
                ) : (
                    isAdmin() && !poll.isCat && Array.isArray(poll.scoreSum) && Array.isArray(poll.dimensions) ? (
                        <div style={styles.scoreContainer}>
                            {Array.isArray(poll.scoreSum) && Array.isArray(poll.dimensions) && poll.dimensions.map((dimension, i) => (
                                <div key={i} style={{
                                    border: '1px solid black',
                                    padding: '10px',
                                    margin: '10px 10px',  // Added horizontal margin
                                    borderRadius: '5px'
                                }}>
                                    <p style={{...styles.paragraph, margin: 0}}>
                                        {dimension}: {poll.scoreSum[i]} / {potential[poll.qIdx-1][i] || 0}
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : null
                )}
                {isAdmin() && poll.scores && poll.question ? (
                    <Paper elevation={3} style={{padding: '20px', margin: '20px 0', color: '#212121'}}>
                        <Typography variant="h6" gutterBottom style={{color: '#212121'}}>
                            Admin Panel:
                        </Typography>
                        <Typography style={{color: '#424242'}}>{`SE: ${poll.scores.std}`}</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={poll.isCat} disabled/>}
                                label="CAT mode"
                                style={{color: '#424242'}}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={!!poll.question.skippable} disabled/>}
                                label="Skippable"
                                style={{color: '#424242'}}
                            />
                        </FormGroup>
                    </Paper>
                ) : null}
            </div>
        </Container>
    )
}

// {
               //     poll.isCat && poll.scores ?
               //         <CurrentScoreChart short_title={short_title}
               //                            theta={poll.scores.score}
               //                            se={poll.scores.std}/> : <></>
               // }