import { useState, useRef } from 'react';

const BaseCardLong = ({ title, score, description, percentile }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  const getScore = (percentile) => {
    if (percentile >= 75) return "High";
    if (percentile <= 25) return "Low";
    return "Average";
  };

  return (
    <div
      className="base-card-long"
      onClick={() => setIsExpanded(!isExpanded)}
      style={{
        padding: '25px 25px',
        cursor: 'pointer',
        height: isExpanded ? `${contentRef.current?.scrollHeight + 100}px` : '80px', // +50px accounts for padding
        transition: 'height 0.3s ease-in-out',
        overflow: 'hidden'
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        {/* First container - Title */}
        <div>
          <h3 style={{ transform: 'translateY(4px)' }}>{title}</h3>
        </div>
        {/* Second container - Score, Percentile bar, Chevron */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '33px'
        }}>
          <span className="cool-gradient"
            style={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '33.6px',
              color: 'black'
            }}
          >
            {getScore(percentile)}
          </span>
          <div className="percentile-bar" style={{
            width: '300px',
            marginBottom: 0
          }}>
            <div
              className="percentile-fill"
              style={{
                width: `${percentile}%`
              }}
            >
              <div className="percentile-marker">
                ▼
              </div>
            </div>
          </div>
          <img
            src="/chevron.svg"
            alt="expand/collapse"
            style={{
              width: '24px',
              height: '24px',
              transition: 'transform 0.3s ease',
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        </div>
      </div>
      <div ref={contentRef} style={{ marginTop: '16px' }}>
        {description}<br/><br/>You score higher than <span style={{
          fontSize: '30px',
          fontWeight: 700,
          lineHeight: '33.6px',
          color: '#9C52F2',
        }}>{percentile}</span>% of people on this trait.
      </div>
    </div>
  );
};
export default BaseCardLong;