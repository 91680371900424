import {React} from 'react';
import {Link} from "react-router-dom";
import '../../css/Signup.css'

export default function Verify() {

    return (
        <div style={{ display: "flex", gap: "4rem", justifyContent: "center", padding: "50px"}} >
                    <div style={{width: "365px"}}>
                        <h2 className="red">Verify Your Account</h2>
                        <br></br>
                        <h2 className="black" style={{fontSize: "20px"}}>We've sent you a verification email. To be able to log in, click the provided verification link.</h2>
                        <br></br>
                        <div>
                            Already verified? <Link to="/signin">Log In</Link>
                        </div>
                    </div>
        </div>
    );
    
}