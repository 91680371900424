import React, { useState, useEffect } from 'react';
import '../css/homepage.css';
import '../css/genomics.css';
import { Link, useNavigate } from 'react-router-dom';
import BaseCardLong from './common/BaseCardLong';
import traitDescriptions from '../data/traitDescriptions.json';
import CircleAnimation from './animations/circleanimation';  // Note the lowercase filename

export default function NewHome() {
  const [selectedTrait, setSelectedTrait] = useState(null);

  const handleTraitClick = (trait) => {
    setSelectedTrait(trait);
  };

  const handleClosePopup = () => {
    setSelectedTrait(null);
  };

  const renderTraitPopup = () => {
    if (!selectedTrait) return null;

    const traitInfo = traitDescriptions[selectedTrait.TRAIT];
    
    // Split references by newlines and render each as a separate paragraph
    const references = traitInfo.reference.split('\n\n').map((ref, index) => (
      <p 
        key={index}
        style={{ 
          fontStyle: 'italic', 
          fontSize: '0.9em', 
          color: '#6b7280',
          marginBottom: index < traitInfo.reference.split('\n\n').length - 1 ? '12px' : '0'
        }}
      >
        {ref}
      </p>
    ));

    return (
      <div className="popup-overlay">
        <div className="trait-popup">
          <h2>{selectedTrait.TRAIT}</h2>
          <div className="trait-content">
            <div className="percentile-info">
              <div className="percentile-bar">
                <div
                  className="percentile-fill"
                  style={{ width: `${parseInt(selectedTrait.PERCENTILE)}%` }}
                >
                  <span className="percentile-marker">▼</span>
                </div>
                <span className="percentile">{selectedTrait.PERCENTILE}th</span>
                <span>Percentile</span>
              </div>
            </div>
            <div className="trait-information">
              <p style={{ marginBottom: '20px' }}>{traitInfo.description}</p>
              <div style={{ borderTop: '1px solid #e5e7eb', paddingTop: '15px' }}>
                {references}
              </div>
            </div>
          </div>
          <div className="trait-buttons">
            <button onClick={handleClosePopup} className="btn btn-default">
              See More Traits
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTraitCards = () => {
    const traits = [
      { TRAIT: "Autism", PERCENTILE: "23" },
      { TRAIT: "Depression", PERCENTILE: "45" },
      { TRAIT: "Education Level", PERCENTILE: "51" },
      { TRAIT: "Height", PERCENTILE: "76" },
      { TRAIT: "Intercranial Volume", PERCENTILE: "92" },
      { TRAIT: "IQ", PERCENTILE: "62" },
      { TRAIT: "Neuroticism", PERCENTILE: "2" },
      { TRAIT: "Schizophrenia", PERCENTILE: "98" },
      // Duplicate traits for continuous scrolling
      { TRAIT: "Autism", PERCENTILE: "53" },
      { TRAIT: "Depression", PERCENTILE: "33" },
      { TRAIT: "Education Level", PERCENTILE: "44" },
      { TRAIT: "Height", PERCENTILE: "76" },
      { TRAIT: "Intercranial Volume", PERCENTILE: "87" },
      { TRAIT: "IQ", PERCENTILE: "80" },
      { TRAIT: "Neuroticism", PERCENTILE: "21" },
      { TRAIT: "Schizophrenia", PERCENTILE: "12" },
    ];

    return (
      <div style={{
        width: '1200px',
        height: '340px',
        background: '#f8f9fa',
        boxShadow: 'inset 0px 0px 40px 40px #f8f9fa',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '40px',
        gap: '10px',
        order: 1
      }}>
        <div style={{
          display: 'flex',
          gap: '28px',
          animation: 'scroll 20s linear infinite',
          paddingTop: '42px'
        }}>
          {traits.map((trait, index) => (
            <div
              key={`${trait.TRAIT}-${index}`}
              className="base-card result-card"
              style={{
                minWidth: '268px',
                cursor: 'pointer'
              }}
              onClick={() => handleTraitClick(trait)}
            >
              <h3>{trait.TRAIT}</h3>
              <div className="percentile-bar">
                <div
                  className="percentile-fill"
                  style={{ width: `${trait.PERCENTILE}%` }}
                >
                  <span className="percentile-marker">▼</span>
                </div>
                <span className="percentile">{trait.PERCENTILE}th</span>
                <span className="percentile-text">Percentile</span>
              </div>
            </div>
          ))}
        </div>
        <style>
          {`
            @keyframes scroll {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(-50%);
              }
            }
            .fade-edge-left {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100px;
              background: linear-gradient(to right, #f8f9fa 0%, rgba(240, 240, 240, 0) 100%);
              z-index: 2;
              pointer-events: none;
            }
            .fade-edge-right {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              width: 100px;
              background: linear-gradient(to left, #f8f9fa 0%, rgba(240, 240, 240, 0) 100%);
              z-index: 2;
              pointer-events: none;
            }
          `}
        </style>
        <div className="fade-edge-left" />
        <div className="fade-edge-right" />
      </div>
    );
  };

  const CardCluster = () => {
    return (
      <div style={{ 
        position: 'relative',
        width: '531px',
        height: '458px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}>
        {/* Background IQ Test Card */}
        <div className="base-card-mini" style={{
          position: 'absolute',
          width: '268px',
          height: '200px',
          right: '220px',
          top: '75px',
          zIndex: 1,
          transition: 'all 0.3s ease'
        }}>
          <img 
            src="/home_card_three.jpeg"
            alt="IQ Test"
            style={{
              width: '228px',
              height: '90px',
              borderRadius: '20px',
              marginBottom: '15px',
              objectFit: 'cover'
            }}
          />
          <h3>IQ Test</h3>
          <p>Discover your IQ score.</p>
        </div>
  
        {/* Big 5 Personality Card */}
        <div className="base-card-mini" style={{
          position: 'absolute',
          width: '268px',
          height: '222px',
          right: '0px',
          top: '20px',
          zIndex: 2,
          transition: 'all 0.3s ease'
        }}>
          <img 
            src="/home_card_two.jpeg"
            alt="Big 5 Personality"
            style={{
              width: '228px',
              height: '90px',
              borderRadius: '20px',
              marginBottom: '15px',
              objectFit: 'cover'
            }}
          />
          <h3>Big 5 Personality</h3>
          <p>Find your personality characteristics.</p>
        </div>
  
        {/* Cultural Worldview Card */}
        <div className="base-card-mini" style={{
          position: 'absolute',
          width: '268px',
          height: '222px',
          right: '140px',
          bottom: '-20px',
          zIndex: 2,
          transition: 'all 0.3s ease'
        }}>
          <img 
            src="/home_card_one.jpeg"
            alt="Cultural Worldview"
            style={{
              width: '228px',
              height: '90px',
              borderRadius: '20px',
              marginBottom: '15px',
              objectFit: 'cover'
            }}
          />
          <h3>Cultural Worldview</h3>
          <p>See how you align politically.</p>
        </div>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-wrapper">
        <div className="landing-section">
          <div className="left-section">
            <h1 className="cool-gradient red" style={{ fontSize: '96px', lineHeight: '134.4px' }}>
              Genotyper
            </h1>
            <h2 className="black" style={{ width: '757px', fontSize: '48px', fontWeight: 700, lineHeight: '52.8px' }}>
              A free tool to unlock the wealth of information hidden within your DNA data.
            </h2>
            <Link
              to="/genomics"
              className="btn btn-default"
              style={{
                marginTop: '40px',
                width: '169px'
              }}
            >
              Get Started
            </Link>
            <div className="logo-container">
              <p style={{ fontSize: '24px', fontWeight: '700', lineHeight: '33.6px' }}>Works with your data from:</p>
              <div className="logo-grid">
                <img
                  src="/23andme_logo.svg"
                  alt="23andMe logo"
                  style={{ height: '100px', objectFit: 'contain' }}
                />
                <img
                  src="/ancestrydna_logo.svg"
                  alt="AncestryDNA logo"
                  style={{ height: '70px', objectFit: 'contain' }}
                />
                <img
                  src="/myheritage_logo.svg"
                  alt="MyHeritage DNA logo"
                  style={{ height: '40px', objectFit: 'contain' }}
                />
              </div>
            </div>
            <div style={{
              position: 'absolute',
              top: '-150px',
              right: '-650px',
              width: '800px',
              height: '800px',
              zIndex: '-1'
            }}>
              <CircleAnimation />
            </div>
          </div>
        </div>
        <div className="section-base">
          <img
            src="/home_folk_one.jpeg"
            alt="woman doing a job that probably doesn't exist anymore"
            style={{
              width: '531px',
              height: '298px',
              borderRadius: '60px',
              objectFit: 'cover'
            }}
          />
          <div className="contribution-content">
            <h2 className="section-title">Upload your data and discover how you score on dozens of traits.</h2>
            <p className="section-text">
              Discover dozens of traits, all within your dna. From intelligence to height, we take data published from leading genetics studies and apply it to you.
            </p>
            <Link
              to="/genomics"
              className="btn btn-default"
              style={{
                width: '209px'
              }}
            >
              Upload My Data
            </Link>
          </div>
        </div>
        {renderTraitCards()}
        {renderTraitPopup()}
        <div className="section-base" style={{ flexDirection: 'row-reverse', marginTop: '100px' }}>
        <CardCluster />
          <div className="contribution-content">
            <h2 className="section-title">Or take free psychological assessments and see how you rank.</h2>
            <p className="section-text">
              We curate scientifically-validated assessments used by leading researchers to measure your personality traits, cognitive abilities, and psychological characteristics with professional accuracy. Completely free!
            </p>
            <Link
              to="/explore"
              className="btn btn-default"
              style={{
                width: '166px'
              }}
            >
              Take a Test
            </Link>
          </div>
        </div>
        <div className="section-base">
          <div className="contribution-content">
            <h2 className="section-title">Get in depth<br/>results.<br/><br/>Backed by real science.</h2>
            <Link
              to="/explore"
              className="btn btn-default"
              style={{
                width: '169px'
              }}
            >
              Get Started
            </Link>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <BaseCardLong
              title="Verbal Intelligence"
              description="Verbal Intelligence reflects a person's ability to understand, process, and express themselves through language. People with high verbal intelligence tend to have strong communication skills, easily grasp complex concepts, and can articulate their thoughts clearly. They often excel at reading comprehension, writing, and verbal problem-solving. This trait is essential for academic success and effective communication in professional settings. Those with lower verbal intelligence may prefer visual or hands-on learning and might find it more challenging to express complex ideas through words."
              percentile={89}
            />
            <BaseCardLong
              title="Compassion"
              description="Compassion reflects how attuned someone is to the emotional needs and feelings of others. People with high compassion tend to be warm and caring, making others feel comfortable around them. They easily empathize with others' situations and often provide emotional support. This trait is crucial for building and maintaining positive relationships. Those with lower compassion tend to focus more on their own needs and may take a more logical, detached approach to others' problems."
              percentile={50}
            />
            <BaseCardLong
              title="Extroversion"
              description="Extroversion reflects how much someone gains energy from social interactions and external stimulation. People with high extroversion tend to be outgoing, enthusiastic, and energized by spending time with others. They often seek out social gatherings and thrive in group settings where they can engage in active discussions and activities. This trait influences how people recharge and interact with the world. Those with lower extroversion (introverts) tend to prefer quieter environments, need time alone to recharge, and may feel drained by extensive social interaction."
              percentile={20}
            />
          </div>
        </div>
        <div className="section-base">
          <img
            src="/home_folk_two.jpeg"
            alt="woman doing a job that probably doesn't exist anymore"
            style={{
              width: '600px',
              height: '454px',
              borderRadius: '60px',
              objectFit: 'cover'
            }}
          />
          <div className="contribution-content">
            <h2 className="section-title">Built by researchers.<br/>For You.</h2>
            <p className="section-text">
              We're geneticists and data scientists who've created a platform that makes genetic data analysis accessible and understandable. Our tool transforms complex genetic data into clear insights using rigorous scientific methods and statistical analysis.
              <br/><br/>
              Your privacy and security are paramount – all data is encrypted and stored in full HIPAA compliance, meeting the highest standards of data protection that research institutions trust.
              <br/><br/>
              Want to learn more about us and how our tool works in greater detail? 
            </p>
            <Link
              to="/about"
              className="btn btn-default"
              style={{
                width: '253px'
              }}
            >
              Learn more about us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}