import React from 'react';
import Container from "react-bootstrap/Container";
import {Button, Stack} from '@mui/material';
import {isLoggedIn, logOutUser} from "../../userAuth";

export default function Account() {

    return (
        <Container className="border p-4 bg-body-tertiary">
            <Stack gap={1}>
                {isLoggedIn() && <Button href="/my-tests">See my tests ‍📊</Button>}
                {isLoggedIn() && <Button href="/profile">Upload Profile Picture 📸</Button>}
                {isLoggedIn() && <Button href="/info" >User Information</Button>}
                <hr/>
                {isLoggedIn() && <Button href="/accountManagement">Account Management 💼</Button>}
                {isLoggedIn() && <Button href="/" onClick={logOutUser}>Log Out 🚪</Button>}
                <hr/>
                <Button href="/">Back To Home 🏠</Button>
            </Stack>
        </Container>
    );
}
