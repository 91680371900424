import React, {useEffect, useState} from 'react';
import {getAllPollCardsApi} from "../api/apiAnon";
import {getToken, handleAuthenticationError} from "../userAuth";
import {useNavigate} from "react-router-dom";
import {checkInfo} from "../api/apiUser";
import PollList from './common/PollList';

export default function Home() {
    const navigate = useNavigate();
    const [pollcards, setPollCards] = useState([]);
    const CACHE_KEY = 'pollCardsData';
    const [tags, setTags] = useState(['loading...']);

    useEffect(() => {
        async function fetchData() {
            try {
                const cachedData = localStorage.getItem(CACHE_KEY);
                if (cachedData) {
                    console.log('Using cached data');
                    let cached_pollcards = JSON.parse(cachedData);
                    let gottenTags = [...new Set(cached_pollcards.map(x => x.tag))];
                    setPollCards(cached_pollcards);
                    setTags(gottenTags)
                }

                const response = await getAllPollCardsApi('true');
                let gottenTags = [...new Set(response.data.map(x => x.tag))];
                setPollCards(response.data);
                setTags(gottenTags);
                localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching poll cards:', error);
            }
        }
        
        async function redirInfo() {
            try {
                const response = await checkInfo({token: getToken()});
                if (response.data.message === 'Info not found') {
                    navigate('/info');
                }
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching poll cards:', error);
            }
        }

        void fetchData();
        void redirInfo();
    }, []);

    return (
        <main className="home-container-nbs">
            <div className="content-wrapper-nbs">
                <div className="hero-section">
                    <div className="hero-image">
                        <img 
                            src="/home_scientist.png" 
                            alt="Description" 
                        />
                    </div>
                    <div className="hero-text-nbs">
                        <h1>Discover Yourself</h1>
                            <p>
                                We've carefully curated a collection of psychological assessments that are widely used in academic research and scientific studies. These tests have been validated across large populations and are regularly employed by researchers to understand human behavior, cognition, and personality.
                            </p>

                            <p>
                                While typically restricted to research settings, we're making these professional-grade instruments freely available to help advance psychological science. Each test in our collection has been selected based on its proven reliability and extensive use in peer-reviewed research.
                            </p>

                            <p>
                                After completing a test, you'll receive clear, easy-to-interpret results. Where appropriate, we'll show you how your scores compare to other participants, providing context and percentile rankings to help you understand your results in a meaningful way.
                            </p>
                    </div>
                </div>
                <div className="subjects-section">
                    <h1>Subjects</h1>
                </div>
                <PollList tags={tags} pollcards={pollcards}/>
            </div>
        </main>
    );
}