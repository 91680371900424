import React from 'react';
import { isAdmin } from '../../userAuth';
import AdminControls from './AdminControls';

const AdminWrapper = ({ children, controls }) => {
  if (!isAdmin()) {
    return children;
  }

  return (
    <>
      {children}
      <AdminControls {...controls} />
    </>
  );
};

export default AdminWrapper;