import React from "react";
import { Stack } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
    deleteAccountApi,
    deleteResponsesApi,
    deleteDnaApi,
} from "../../api/apiAuth";
import { getAnonymousKey, getToken, logOutUser } from "../../userAuth";

export default function AccountManagement({showPassChange}) {
    const navigate = useNavigate();

    const deleteAccount = async (e) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "This will permanently delete your account and all associated data.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                await deleteAccountApi({
                    token: getToken(),
                    anon_key: getAnonymousKey(),
                });
                await deleteDnaApi({
                    token: getToken(),
                });
                logOutUser();
                Swal.fire("Deleted!", "Your account has been deleted.", "success");
            } catch (error) {
                console.log(error);
                Swal.fire("Error", "Account deletion failed. Are you logged in?", "error");
            }
        }
    };

    const deleteResponses = async (e) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "This will permanently delete all your responses.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete them!",
        });

        if (result.isConfirmed) {
            try {
                await deleteResponsesApi({
                    token: getToken(),
                });
                Swal.fire("Deleted!", "Your responses have been deleted.", "success");
            } catch (error) {
                Swal.fire("Error", "Response deletion failed. Are you logged in?", "error");
            }
        }
    };

    const deleteDNA = async (e) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "This will permanently delete your DNA data.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                await deleteDnaApi({
                    token: getToken(),
                });
                Swal.fire("Deleted!", "Your DNA data has been deleted.", "success");
            } catch (error) {
                Swal.fire("Error", "DNA deletion failed. Are you logged in?", "error");
            }
        }
    };

    const changePassword = () => {
        showPassChange();
    };

    return (
        <Stack gap={1}>
            {<button className="light-button" href="/" onClick={changePassword}>Change Password 🔑</button>}
            {<button className="light-button" href="/" onClick={deleteAccount}>Delete Account 🗑️</button>}
            {<button className="light-button" href="/" onClick={deleteResponses}>Delete Responses 🗑️</button>}
            {<button className="light-button" href="/" onClick={deleteDNA}>Delete DNA 🗑️</button>}
            {<button className="light-button" href="/" onClick={logOutUser}>Log Out</button>}
        </Stack>
    );
}
