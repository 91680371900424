import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../lotties/circles';

const CircleAnimation = ({ width = 800, height = 800 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
      isClickToPauseDisabled={true}
    />
  );
};

export default CircleAnimation;
