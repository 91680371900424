import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// styles
import 'bootstrap/dist/css/bootstrap.css';
import './css/global.css';

// components
import Home from './components/Home';
import Explore from './components/Explore';
import Signin from './components/common/Signin';
import Signup from './components/common/Signup';
import CreatePoll from './components/createpoll/CreatePoll';
import UpdatePoll from './components/updatepoll/UpdatePoll';
import Norms from './components/norms/Norms';
import TakePollDyn from './components/takepoll/TakePoll';
import UserResponse from './components/viewresults/UserResponse';
import TopBar from './components/topbar/TopBar';
import Account from './components/account/Account';
import AllResponses from "./components/viewresults/AllResponses";
import Genomics from "./components/genomics/Genomics";
import {FooterBar} from "./components/footer/FooterBar";
import {About} from "./components/footer/About";
import {Contact} from "./components/footer/Contact";
import {PrivacyPolicy} from "./components/footer/PrivacyPolicy";
import {TermsConditions} from "./components/footer/TermsConditions";
import TakeNormPoll from './components/takepoll/TakeNormPoll';
import {GoogleOAuthProvider} from '@react-oauth/google';
import ChangePassword from "./components/account/ChangePassword";
import MyTests from "./components/account/MyTests";
import AccountManagement from "./components/account/AccountManagement";
import {Stats} from "./components/footer/Stats";
import Profile from "./components/profile/Profile";
import Info from "./components/profile/Info";
import Verify from './components/common/Verify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId="166634558445-svsfm230lgbn1974ukl4sjcseo0ejv53.apps.googleusercontent.com">
        <React.StrictMode>
            <BrowserRouter>
                <TopBar/>
                <Routes>
                    <Route path='/explore' element={<Explore/>}/>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/create' element={<CreatePoll/>}/>
                    <Route path='/signin' element={<Signin/>}/>
                    <Route path='/signup' element={<Signup/>}/>
                    <Route path='/viewAll/:short_title' element={<AllResponses/>}/>
                    <Route path='/view/:short_title' element={<UserResponse/>}/>
                    <Route path="/poll/:short_title" element={<TakePollDyn/>}/>
                    <Route path="/norms-test/:short_title" element={<TakeNormPoll/>}/>
                    <Route path='/genomics' element={<Genomics/>}/>
                    <Route path="/account" element={<Account/>}/>
                    <Route path="/accountManagement" element={<AccountManagement/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/my-tests" element={<MyTests />} />
                    <Route path="/my-tests/:short_title/:title/:details" element={<MyTests />} />
                    <Route path="/changePassword" element={<ChangePassword/>}/>
                    <Route path="/update/:short_title" element={<UpdatePoll/>}/>
                    <Route path="/norms/:short_title" element={<Norms/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/privacy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms" element={<TermsConditions/>}/>
                    <Route path="/stats" element={<Stats/>}/>
                    <Route path="/info" element={<Info/>}/>
                    <Route path="/verify" element={<Verify/>}/>
                </Routes>
                <FooterBar/>
            </BrowserRouter>
        </React.StrictMode>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
