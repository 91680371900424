import { FormControl, FormGroup, RadioGroup, TextField } from "@mui/material";
import ChoiceCheckbox from "./ChoiceCheckbox";
import ChoiceRadio from "./ChoiceRadio";
import DOMPurify from "dompurify";
import '../../css/Poll.css';

export default function CurrentQuestion({ question, answer, setAnswer, selections, setSelections, allAnswers }) {
    let  safeHTML = DOMPurify.sanitize(question?.prompt);
    safeHTML= safeHTML.replace(/<(?!img\b)[^>]*>(.*?)<\/[^>]*>/gi, '<h2 class="black">$1<h/2>');

    return (
        <div style={{ margin: '20px', padding: '20px' }}>
            <div
                className="image-container"
                style={{ textAlign: 'center' }}
                dangerouslySetInnerHTML={{ __html: safeHTML }}
            />
            <br></br>
            <style jsx>{`
                .image-container img {
                    width: 100%;
                    height: auto;
                    max-width: 200px; /* Adjust as necessary */
                }

                @media (min-width: 600px) {
                    .image-container img {
                        max-width: 500px;
                    }
                }

                @media (min-width: 900px) {
                    .image-container img {
                        max-width: 500px;
                    }
                }
            `}</style>
            {question.type === "Long Answer" && (
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    maxRows={30}
                    label="Reply (Long Answer)"
                    value={answer.reply}
                    onChange={(event) => {
                        setAnswer({
                            reply: event.target.value,
                            choice_id: null,
                            q_id: question.id,
                        });
                    }}
                />
            )}
            {question.type === "Short Answer" && (
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Reply (Short Answer)"
                    value={answer.reply}
                    onChange={(event) => {
                        setAnswer({
                            reply: event.target.value,
                            choice_id: null,
                            q_id: question.id,
                        });
                    }}
                />
            )}
            {question.type === "Multiple Choice" && (
                <>
                    {question.multi && (
                        <FormGroup>
                            {question.choices.map((choice) => (
                                <ChoiceCheckbox
                                    key={choice.id}
                                    choice={choice}
                                    selections={selections}
                                    setSelections={setSelections}
                                    allAnswers={allAnswers}
                                    q_id={question.id}
                                />
                            ))}
                        </FormGroup>
                    )}
                    {!question.multi && (
                        <div className="choices-container">
                                {question.choices.map((choice) => (
                                    <ChoiceRadio
                                        key={choice.id}
                                        choice={choice}
                                        answer={answer}
                                        setAnswer={setAnswer}
                                    />
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
