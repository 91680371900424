import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { getAllPollsApi } from '../../api/apiAnon';
import { handleAuthenticationError } from '../../userAuth';
import { FaCog } from 'react-icons/fa'; // Import settings icon
import Popup from 'reactjs-popup';
import AccountManagement from '../account/AccountManagement';
import { useNavigate } from 'react-router-dom';
import ChangePassword from '../account/ChangePassword';
import { FaArrowLeft } from 'react-icons/fa';
import ProfileUpload from './ProfileUpload';

function TopBar() {
    const nav = useNavigate();
    const [polls, setPolls] = useState(null);
    const [isCardActive, setIsCardActive] = useState(false);
    const [isCardActive2, setIsCardActive2] = useState(false);
    const handleDeactivateCard = () => setIsCardActive(false);
    const handleDeactivateCard2 = () => setIsCardActive2(false);
    const location = useLocation();
    const [showPassChange, setShowPassChange] = useState(false);

    const changePass = () => {
        setShowPassChange(!showPassChange)
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getAllPollsApi('false');
                setPolls(response.data);
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }

        void fetchData();
    }, []);

    const getUser = () => {
        const user = localStorage.getItem('email');
        if (user) {
            return <Link to="/account">{user}</Link>;
        }
    };

    const handleActivateCard = () => {
        if( getUser() )  {
            setIsCardActive(true)
        }
        else {
            nav('/signin')
        }
    }

    const handleActivateCard2 = () => {
        if( getUser() )  {
            setIsCardActive2(true)
        }
        else {
            nav('/signin')
        }
    }
    
    const navButtons = [
        { path: '/', label: 'My Home' },
        { path: '/explore', label: 'Explore'},
        { path: '/genomics', label: 'My DNA' },
        { path: '/my-tests', label: 'My Tests' },
        { path: '/about', label: 'About' },
    ];

    return (
    <>
     <Popup
                    open={isCardActive}
                    closeOnDocumentClick
                    onClose={handleDeactivateCard}
                    modal
                    overlayStyle={{ background: 'transparent' }}
                    contentStyle={{
                        width: "300px",
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '64px',
                        background: '#F0F0F0',
                        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
                    }}
        >
            <>
            { !showPassChange &&
            <>
                <h2 className="black">Settings</h2>
                <AccountManagement showPassChange={changePass}></AccountManagement>
            </>
            }
            { showPassChange &&
            <>
             <div onClick={() => setShowPassChange(false)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '8px' }}>
                <FaArrowLeft size={20} color="black" /> {/* Back arrow icon */}
                <h2 className="black" style={{ margin: 0 }}>Back</h2>
            </div>
            <ChangePassword />
            </>
            }

            </>
        </Popup>
        <Popup
                    open={isCardActive2}
                    closeOnDocumentClick
                    onClose={handleDeactivateCard2}
                    modal
                    overlayStyle={{ background: 'transparent' }}
                    contentStyle={{
                        width: "500px",
                        display: 'flex',
                        padding: '20px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '64px',
                        background: '#F0F0F0',
                        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
                    }}
        >
            <>
            { 
            <>
                <ProfileUpload></ProfileUpload>
            </>
            }

            </>
        </Popup>
        <Navbar expand="lg" className="bg-body-tertiary mb-4">
            <Container>
                <Navbar.Collapse className="justify-content-start">
                    <div>
                    {getUser() && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Circle Profile Picture */}
                        <img
                        src={localStorage.getItem('pfp')}
                        alt="Profile"
                        style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            marginRight: '10px',
                            cursor: "pointer"
                        }}
                        onClick={handleActivateCard2}
                        />
                        {/* Welcome Text */}
                        <h2 className="black" style={{ fontSize: '16px' }}>
                        {localStorage.getItem('name')}
                        </h2>
                    </div>
                    )}
                        {!getUser() &&
                            <>
                            <button className="light-button"><Link to="/signin" className="link">Log In</Link></button>
                            <div style={{width: '20px', display: 'inline-block' }}></div>
                            <button className="light-button"><Link to="/signup" className="link">Register</Link></button>
                            </>
                        }
                    </div>
                </Navbar.Collapse>

                <Navbar.Collapse id="basic-navbar-nav">
                    <div className="d-flex mx-auto justify-content-center gap-4">
                        {navButtons.map((button, index) => (
                            <button
                                key={index}
                                onClick={() => (window.location.href = button.path)}
                                className={`nav-button ${location.pathname === button.path ? 'nav-button-selected' : 'nav-button-default'}`}
                            >
                                {button.label}
                            </button>
                        ))}
                    </div>
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end">
                    <div>
                        <button
                            onClick={handleActivateCard}
                            className="settings-button"
                            style={{
                                backgroundColor: '#f8f9fa', // Light grey background
                                border: '1px solid #ced4da',
                                borderRadius: '50%',
                                width: '48px', // Ensure it's perfectly round
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '0', // Remove default padding
                                cursor: 'pointer',
                            }}
                        >
                            <FaCog size={24} color="#6c757d" /> {/* Settings gear */}
                        </button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
    );
}

export default TopBar;
