import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { cancelDNAFile, uploadDNAFile, progressState } from "../../api/apiDNA";
import { getToken, handleAuthenticationError, isLoggedIn } from "../../userAuth";
import traitDescriptions from '../../data/traitDescriptions.json';
import AdminWrapper from './AdminWrapper';
import '../../css/genomics.css';

const PROCESS_STEPS = ['In Queue', 'Imputing', 'Analyzing', 'Final Processing'];

const traitSimpleDescriptions = {
  'Autism': 'A developmental condition affecting social interaction and behavior patterns',
  'Depression': 'A mental health condition characterized by persistent sadness and loss of interest',
  'Education Level': 'The amount of formal education completed',
  'Height': 'Physical stature or vertical measurement of a person',
  'Intercranial Volume': 'The total volume within the skull, including the brain and surrounding fluid',
  'IQ': 'A standardized measure of cognitive ability and problem-solving skills',
  'Neuroticism': 'A personality trait reflecting emotional sensitivity and tendency to experience negative emotions',
  'Schizophrenia': 'A complex mental disorder affecting thinking, perception, and behavior'
};

const traits = [
    'Autism',
    'Depression',
    'Education Level',
    'Height',
    'Intercranial Volume',
    'IQ',
    'Neuroticism',
    'Schizophrenia'
  ];

const processes = [
  {
    number: "1",
    title: "Imputation",
    description: "DNA testing services like 23andMe look at specific points in your genome - think of it as taking snapshots at key locations. We use advanced scientific methods to fill in the areas between these snapshots, giving us a more complete picture of your genetic profile."
  },
  {
    number: "2",
    title: "Calculation",
    description: "We then compare your genetic profile with large-scale scientific studies that have discovered links between genes and traits. This allows us to calculate your genetic scores across various characteristics that researchers have studied."
  },
  {
    number: "3",
    title: "Results",
    description: "Finally, we translate complex genetic data into clear, meaningful insights. You'll see how your genetic scores compare to research populations, presented in an easy-to-understand format that helps you make sense of your unique genetic profile."
  }
]; 

export default function Genomics() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/signup');
    }
  }, [navigate]);

  // Stage management
  const [currentView, setCurrentView] = useState('upload'); // upload, processing, results
  
  // Upload state
  const [isUploadMode, setIsUploadMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  
  // Processing state
  const [currentStage, setCurrentStage] = useState('In Queue');
  const [stageDescription, setStageDescription] = useState('');
  
  // Results state
  const [results, setResults] = useState(null);
  const [selectedTrait, setSelectedTrait] = useState(null);

  // Cancel Popup
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  // Cache management
  const [uploadState, setUploadState] = useState(false);

  // Initial state check & polling
  useEffect(() => {
    // Always check progress on initial load
    checkProgress();

    // Set up polling if in processing view
    let interval;
    if (currentView === 'processing') {
      interval = setInterval(checkProgress, 300000);
    }
    return () => clearInterval(interval);
  }, [currentView]);

  const checkProgress = async () => {
    if (!isLoggedIn()) return;
    
    try {
      const response = await progressState({ token: getToken() });
      const { progress, results } = response.data;

      if (!progress) {
        setCurrentView('upload');
        setUploadState(false);
        return;
      }

      setUploadState(true);

      if (progress.current === 'Complete' && results) {
        setResults(results.results);
        setCurrentView('results');
      } else if (progress.current === 'Error' || progress.current === 'Cancelled') {
        setCurrentView('upload');
        setUploadState(false);
        alert(`${progress.description}`)
      } else {
        setCurrentView('processing');
        setCurrentStage(progress.mappedStage);
        setStageDescription(progress.description);
      }
    } catch (error) {
      handleAuthenticationError(error);
      console.error('Error checking progress:', error);
    }
  };

  const handleUploadClick = () => {
    setIsUploadMode(true);
  };

  const handleCancel = () => {
    setIsUploadMode(false);
    setSelectedFile(null);
    setIsUploading(false);
    setShowTerms(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = () => {
    setShowTerms(true);
  };

  const handleAcceptTerms = async () => {
    setShowTerms(false);
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("token", getToken());
      formData.append("file", selectedFile, selectedFile.name);
      const response = await uploadDNAFile(formData);
      setUploadState(true);
      await checkProgress();
    } catch (error) {
      handleAuthenticationError(error);
      
      // Handle error responses with messages
      if (error.response) {
        alert(`Upload failed (${error.response.status}). ${error.response.data?.message || ''}`);
      } else {
        alert('A key component in the upload process is offline. Please try again later.');
      }
      
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancelJobClick = () => {
    setShowCancelConfirm(true);
  };
  
  const handleCancelJobConfirm = async () => {
    try {
      await cancelDNAFile({ token: getToken() });
      setUploadState(false);
      setCurrentView('upload');
    } catch (error) {
      handleAuthenticationError(error);
      console.error('Error canceling job:', error);
    } finally {
      setShowCancelConfirm(false);
    }
  };

  const renderCancelConfirmPopup = () => {
    if (!showCancelConfirm) return null;
    
    return (
      <div className="popup-overlay">
        <div className="terms-popup" style={{ height: '280px' }}>
          <h2>Cancel Job?</h2>
          <div className="terms-content">
            <p>Are you sure you want to cancel this job? This action cannot be undone.</p>
          </div>
          <div className="terms-buttons">
            <button 
              className="btn cancel-button" 
              onClick={() => setShowCancelConfirm(false)}
            >
              No, Keep Processing
            </button>
            <button 
              className="btn btn-default" 
              onClick={handleCancelJobConfirm}
            >
              Yes, Cancel Job
            </button>
          </div>
        </div>
      </div>
    );
  };

// Trait popup handlers and rendering for Results view
const handleTraitClick = (trait) => {
    setSelectedTrait(trait);
  };

  const handleClosePopup = () => {
    setSelectedTrait(null);
  };

  const renderTraitPopup = () => {
    if (!selectedTrait) return null;
  
    const traitInfo = traitDescriptions[selectedTrait.TRAIT];
  
    const references = traitInfo.reference.split('\n\n').map((ref, index) => (
      <p 
        key={index}
        style={{ 
          fontStyle: 'italic', 
          fontSize: '0.9em', 
          color: '#6b7280',
          marginBottom: index < traitInfo.reference.split('\n\n').length - 1 ? '12px' : '0'
        }}
      >
        {ref}
      </p>
    ));
  
    return (
      <div className="popup-overlay">
        <div className="trait-popup">
          <h2>{selectedTrait.TRAIT}</h2>
          <div className="trait-content">
            <div className="percentile-info">
              <div className="percentile-bar">
                <div
                  className="percentile-fill"
                  style={{ width: `${parseInt(selectedTrait.PERCENTILE)}%` }}
                >
                  <span className="percentile-marker">▼</span>
                </div>
                <span className="percentile">{selectedTrait.PERCENTILE}</span>
                <span>Percentile</span>
              </div>
            </div>
            <div className="trait-information">
              <p style={{ marginBottom: '20px' }}>{traitInfo.description}</p>
              <div style={{ borderTop: '1px solid #e5e7eb', paddingTop: '15px' }}>
                {references}
              </div>
            </div>
          </div>
          <div className="trait-buttons">
            <button onClick={handleClosePopup} className="btn btn-default">
              See More Traits
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTermsPopup = () => {
    if (!showTerms) return null;

    return (
      <div className="popup-overlay">
        <div className="terms-popup">
          <h2>Terms of Service</h2>
          <div className="terms-content">
            <div className="terms-section">
              <h3>1. Data Usage</h3>
              <p>By uploading your genetic data, you agree to allow us to process and analyze your data for the purpose of providing trait analysis results.</p>
            </div>
            <div className="terms-section">
              <h3>2. Privacy</h3>
              <p>Your genetic data will be encrypted and stored securely. We do not share your individual-level genetic data with third parties without your explicit consent.</p>
            </div>
            <div className="terms-section">
              <h3>3. Results</h3>
              <p>The trait analysis results are for informational purposes only and should not be used for medical decisions without consulting healthcare professionals.</p>
            </div>
            {/* Add more terms sections as needed */}
          </div>
          <div className="terms-buttons">
            <button onClick={handleCancel} className="btn cancel-button">Cancel</button>
            <button onClick={handleAcceptTerms} className="btn btn-default">Accept</button>
          </div>
        </div>
      </div>
    );
  };

  const renderUploadBox = () => {
    if (isUploading) {
      return (
        <div className="upload-box">
          <img src="/loader.svg" alt="Loading" className="upload-icon spinning" />
          <h3>Uploading</h3>
          <p>{selectedFile.name}</p>
        </div>
      );
    }

    if (!selectedFile) {
      return (
        <div 
          className="upload-box"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img src="/upload.svg" alt="Upload" className="upload-icon" />
          <h3>Drag & drop file to upload</h3>
          <p>AncestryDNA and 23andMe files end in .zip or .txt</p>
          <div className="upload-buttons">
            <button className="btn cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <label className="btn btn-default">
              Choose File
              <input
                type="file"
                onChange={handleFileSelect}
                accept=".zip,.txt"
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </div>
      );
    }

    return (
      <div className="upload-box">
        <img src="/check.svg" alt="Ready" className="upload-icon" />
        <h3>Ready!</h3>
        <p>{selectedFile.name}</p>
        <div className="upload-buttons">
          <button className="btn cancel-button" onClick={handleCancel}>
            Cancel
          </button>
          <button 
            className="btn btn-default"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    );
  };

  // Result card rendering
  const renderTraitCard = (trait) => {
    // Add the description and reference from imported traitDescriptions to the trait object
    const traitWithInfo = {
      ...trait,
      description: traitDescriptions[trait.TRAIT].description,
      reference: traitDescriptions[trait.TRAIT].reference
    };
    
    return (
      <div
        key={trait.TRAIT}
        className="base-card result-card"
        style={{ height: '175px', width: '268px'}}
        onClick={() => handleTraitClick(traitWithInfo)}
      >
        <h3>{trait.TRAIT}</h3>
        <div className="percentile-bar">
          <div
            className="percentile-fill"
            style={{ width: `${parseInt(trait.PERCENTILE)}%` }}
          >
            <span className="percentile-marker">▼</span>
          </div>
          <span className="percentile">{trait.PERCENTILE}</span>
          <span className="percentile-text">Percentile</span>
        </div>
      </div>
    );
  };

  const renderCategorySection = (categoryTitle, categoryTraits, isLast = false) => (
    <>
      <div className="category-section">
        <h2>{categoryTitle}</h2>
        <div className="results-grid">
          {categoryTraits.map(renderTraitCard)}
        </div>
      </div>
      {!isLast && <div className="category-divider" />}
    </>
  );

  // Main view rendering logic based on currentView state
  const renderContent = () => {
    switch (currentView) {
      case 'processing':
        return (
          <div className="genomics-container processing">
            <div className="content-wrapper">
              <div className="processing-main-box">
                <div className="processing-content">
                  <div className="processing-left-content">
                    <h1 className="red">DNA Trait Analysis</h1>
                    <div className="progress-tracker">
                      {PROCESS_STEPS.map((step) => (
                        <div key={step} className="progress-step">
                          <span className={step === currentStage ? 'active' : ''}>
                            {step}
                          </span>
                          {step !== PROCESS_STEPS[PROCESS_STEPS.length - 1] && (
                            <img
                              src="/arrow_right.svg"
                              alt="arrow"
                              className="progress-arrow"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="processing-status">
                      <h2>{stageDescription}</h2>
                    </div>
                  </div>
                  <div className="vessel-circles" style={{position: "absolute", top: "0", right: "0", width: "600px", height: "600px", zIndex: "0"}}>
                    <img src="/vesselcircles.gif" alt="Processing visualization" style={{ width: '100%', height: '100%', objectFit: 'contain'}} />
                  </div>
                </div>
                <p className="processing-description">
                  Processing genetic data is highly resource intensive and it will take some time
                  for our servers to crunch the numbers. We'll send you an email when your results are ready.
                </p>
              </div>
              <button className="cancel-link" onClick={handleCancelJobClick}>
                Made a mistake? Cancel This Job
              </button>
              {renderCancelConfirmPopup()}
              <div className="meantime-section">
                <h2>Want to do something else in the meantime?</h2>
                <p>Take a personality or intelligence test.</p>
                <Link to="/explore" className="btn btn-default">
                  Discover Tests
                </Link>
              </div>

              <div className="traits-section">
                <h2 className="section-header">
                  Soon you'll learn insights into dozens of traits:
                </h2>
                <div className="traits-grid">
                  {traits.map((trait, index) => (
                    <div key={index} className="base-card trait-card">
                      <h3 className="grey">{trait}</h3>
                      <p>{traitSimpleDescriptions[trait]}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 'results':
        if (!results) return <div>Loading...</div>;
        
        const psychologicalTraits = results.filter(t => t.CATEGORY === 'Psychological');
        const physiologicalTraits = results.filter(t => t.CATEGORY === 'Physiological');
        const socioeconomicTraits = results.filter(t => t.CATEGORY === 'Socioeconomic');

        return (
          <div className="genomics-container">
            <div className="content-wrapper">
              <div className="results-header">
                <h1 className="red">Your DNA results</h1>
              </div>
              {renderCategorySection('Psychological Characteristics', psychologicalTraits)}
              {renderCategorySection('Physiological Characteristics', physiologicalTraits)}
              {renderCategorySection('Socioeconomics', socioeconomicTraits, true)}
              {renderTraitPopup()}
            </div>
          </div>
        );

      default: // Upload view
        return (
          <div className="genomics-container">
            <div className="content-wrapper">
              <div className="hero-section">
                <div className="hero-content">
                  <h1 className="red">DNA Trait Analysis</h1>
                  <p className="p-size" style={{ fontSize: '24px', fontWeight: '700', lineHeight: '33.6px' }}>
                    Discover next level insights into your 23andMe and AncestryDNA data with a tool developed
                    by genetics researchers. Learn how you score on a variety of traits relative to the rest
                    of the world while contributing to scientific research. All completely free!
                  </p>
                </div>
                <img
                  src="/genomics_scientist.jpeg"
                  alt="DNA Analysis Illustration"
                  className="hero-image"
                />
              </div>

              <div className="bottom-button-container">
                {!isUploadMode ? (
                  <button className="btn btn-default" onClick={handleUploadClick}>
                    Upload My Data
                  </button>
                ) : (
                  renderUploadBox()
                )}
              </div>

              {renderTermsPopup()}

              <div className="hipaa-notice">
              <img
                src={isUploadMode ? "/shield.svg" : "/caduceus.svg"}
                alt="HIPAA Compliant"
                style={{
                  width: isUploadMode ? 48 : 200,
                  height: isUploadMode ? 48 : 105,
                  transition: 'all 0.3s ease-out'
                }}
              />
                <p className="hipaa-text" style={{ margin: '0' }}>
                  Your privacy and security is of the highest importance to us. Being researchers we know
                  the importance of protecting peoples information and preventing it from being tied back
                  to you, especially when it comes to genetic information. That's why we encrypt & anonymize
                  all your data and processes it on private HIPAA compliant servers that only we have access to.
                </p>
              </div>

              <div className="traits-section">
                <h2 className="section-header">The traits we analyze:</h2>
                <div className="traits-grid">
                  {traits.map((trait, index) => (
                    <div key={index} className="base-card trait-card">
                      <h3 className="grey">{trait}</h3>
                      <p>{traitSimpleDescriptions[trait]}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="steps-section">
                  <h2 className="section-header">How it works:</h2>
                  <div className="steps-container" style={{ gap: '140px' }}>
                      {processes.map((process, index) => (
                          <div key={index} className="step-card" style={{ minHeight: '296px' }}>
                              <div className="step-header">
                                  <div className="step-number">
                                      {process.number}
                                  </div>
                                  <h3>{process.title}</h3>
                              </div>
                              <p>{process.description}</p>
                          </div>
                      ))}
                  </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <AdminWrapper
      controls={{
        setCurrentView,
        setUploadState,
        setResults,
        setCurrentStage,
        setStageDescription
      }}
    >
      {renderContent()}
    </AdminWrapper>
  );
}