import {useEffect, useState} from "react";
import {getPollResults} from "../../api/apiUser";
import {handleAuthenticationError} from "../../userAuth";
import "../../css/results.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-tooltip/dist/react-tooltip.css';
import Switch from "react-switch";
import { lazyNorm } from "../../utils";
import { ClipLoader } from "react-spinners";

export default function ViewResult({short_title, title, description}) {
    const [residx, setResIdx] = useState(0);
    const [responses, setResponses] = useState([]);
    const [allScores, setAllScores] = useState([]);
    const [allNormedScores, setAllNormedScores] = useState([]);
    const [sampleSize, setSampleSize] = useState(0);
    const [dimensions, setDimensions] = useState(null);
    const [percentiles, setPercentiles] = useState(null);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleToggle = (nextChecked) => {
        setChecked(nextChecked);
    };

    const computePercentile = (myScore, scores) => {
        const sortedScores = [...scores].sort((a, b) => a - b);
        const count = sortedScores.filter(score => score <= myScore).length;
        const percentile = count / sortedScores.length;
        return Math.floor(percentile * 100) / 100;
    };


    useEffect(() => {
        async function fetchPollResults() {
            try {
                const pollResults_ = await getPollResults(short_title);
                console.log('POLLRESULTS', pollResults_.data);
                setResIdx(pollResults_.data.responses.length-1);
                setChecked(false);
                setAllNormedScores(pollResults_.data.allNormedScores)
                setAllScores(pollResults_.data.allScores)
                setResponses(pollResults_.data.responses)
                setSampleSize(pollResults_.data.allScores.length)
                const dims = Object.keys(pollResults_.data.allScores)
                setDimensions(dims)
                let percs = {}
                console.log(residx)
                for (const dim of dims) {
                    percs[dim] =  computePercentile(pollResults_.data.responses[pollResults_.data.responses.length-1].total_score[dim], pollResults_.data.allScores[dim])
                }
                setPercentiles(percs)
                setLoading(false);

            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching results:', error);
            }
        }
        void fetchPollResults();
    }, [short_title]);
    const percentage = 50

    const handleSelectChange = (event) => {
        setResIdx(event.target.value-1);
        let percs = {}
        for (const dim of dimensions) {
            percs[dim] =  computePercentile(responses[event.target.value-1].total_score[dim], allScores[dim])
        }
        setPercentiles(percs)

        console.log("Selected number:", event.target.value); // Log the selected value
      };

    if (!loading){
        return (
        <>
            <div 
                style={{ 
                    width: "17%", 
                    height: "20px", 
                    backgroundColor: "transparent", 
                    visibility: "hidden" 
                }}>
            </div>
            <div className="results_title_card" style={{ textAlign: 'center', margin: '20px 0' }}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <h2 className="red" style={{fontSize: "28px" }}>{title}</h2>
                    <div style={{display:"flex",flexDirection: "row", gap:"20px"}}>
                        <h2 className="black" style={{fontSize: "19px", margin:"14px"}}>Response #:</h2>
                        <select
                            className="light-button"
                            onChange={handleSelectChange}
                            value={residx+1}
                            style={{
                            padding: "15px",
                            paddingLeft: "25px",
                            paddingRight: "25px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            backgroundColor: "#f9f9f9",
                            }}
                        >
                            {Array.from({ length: responses.length }, (_, i) => i + 1).map((num) => (
                            <option key={num} value={num}>
                                {num}
                            </option>
                            ))}
                        </select>
                    </div>
                
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <p style={{ fontSize: '16px', color: '#555' }}>{description}</p>
                    { responses[residx]?.normed_score && 
                        <div style={{display: "flex", flexDirection: "row",  alignItems: "center", gap: "45px" }}>
                            <h2 className="black" style={{fontSize: "20px"}}>
                                Norming:
                            </h2>
                            <Switch
                            onChange={handleToggle}
                            checked={checked}
                            offColor="#888"
                            onColor="#0bf"
                            />
                        </div>
                    }
                </div>
            </div>
            <div 
                style={{ 
                    width: "17%", 
                    height: "20px", 
                    backgroundColor: "transparent", 
                    visibility: "hidden" 
                }}>
            </div>
            <div className="results_score_container">
            {!checked && dimensions && percentiles && responses[residx] && dimensions.map((dimension) => 
                <div className="results_title_card">
                    <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                        <h2 className="black" style={{fontSize: "20px"}}>{dimension}: {responses[residx].total_score[dimension]}</h2>
                        <h2 className="black" style={{fontSize: "20px"}}>You scored better than {(percentiles[dimension] * 100).toFixed(0)}% of takers!</h2>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft:"720px", width: 100, height: 100}}>
                    <CircularProgressbar
                    value={(percentiles[dimension] * 100).toFixed(0)}
                    text={`${(percentiles[dimension] * 100).toFixed(0)}%`}
                    data-tooltip-id={`tooltip-${dimension}`}
                    data-tooltip-content={`You scored better than ${(percentiles[dimension] * 100).toFixed(0)}% of takers!`}
                    styles={{
                        path: { stroke: '#6a0dad' },
                        text: { fill: '#6a0dad', fontSize: '16px' },
                        trail: { stroke: '#e0e0e0' },
                    }}
                />
                    </div>
                </div>
            )}
            {checked && dimensions && percentiles && responses[residx] && dimensions.map((dimension) => 
                <div className="results_title_card">
                    <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
                        <h2 className="black" style={{fontSize: "20px"}}>{dimension}: {responses[residx].normed_score[dimension]?.toFixed(2)}</h2>
                        <h2 className="black" style={{fontSize: "20px"}}>You scored better than {lazyNorm(responses[residx].normed_score[dimension]) * 100}% of the population!</h2>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft:"720px", width: 100, height: 100}}>
                    <CircularProgressbar
                    value={lazyNorm(responses[residx].normed_score[dimension]) * 100}
                    text={`${lazyNorm(responses[residx].normed_score[dimension]) * 100}%`}
                    data-tooltip-id={`tooltip-${dimension}`}
                    data-tooltip-content={`You scored better than ${lazyNorm(responses[residx].normed_score[dimension]) * 100}% of the population!`}
                    styles={{
                        path: { stroke: '#6a0dad' },
                        text: { fill: '#6a0dad', fontSize: '16px' },
                        trail: { stroke: '#e0e0e0' },
                    }}
                />
                    </div>
                </div>
            )}
            </div>
        </>    
        )
    }
    else {
        return (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", padding:"300px"}}>
                     <ClipLoader color="#36d7b7" size={50} />
            </div>
        );
    }
}
