import Container from "react-bootstrap/Container";
import {changePasswordApi} from "../../api/apiAuth";
import {getToken} from "../../userAuth";
import {Card} from "react-bootstrap";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Swal from 'sweetalert2';

export default function ChangePassword() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const executeChange = async () => {
        if (password !== confirmPassword) {
            await Swal.fire({
                icon: 'error',
                title: "Passwords don't match",
                timer: 800,
                showConfirmButton: false,
                text: 'Please make sure both passwords are the same.',
            });
            return;
        }

        if (password.length < 5) {
            await Swal.fire({
                icon: 'error',
                title: 'Invalid Password',
                timer: 800,
                showConfirmButton: false,
                text: 'Password must be longer than 5 characters!',
            });
            return;
        }

        try {
            await changePasswordApi({
                token: getToken(),
                new_password: password,
            });

            await Swal.fire({
                icon: 'success',
                title: 'Password Changed',
                timer: 800,
                showConfirmButton: false,
                text: 'Your password has been changed successfully!',
            });
        } catch (e) {
            await Swal.fire({
                icon: 'error',
                title: 'Change Failed',
                timer: 800,
                showConfirmButton: false,
                text: 'Failed to change password! Please try again later.',
            });
            console.error(e);
        }
    };


    return (
                    <div className="Auth-form-container">
                        <form className="Auth-form">
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                                <input type="password"
                                       className="form-control confirm-password"
                                       placeholder="Confirm Password"
                                       id="confirm-password"
                                       name="confirm-password"
                                       value={confirmPassword}
                                       onChange={(e) => setConfirmPassword(e.target.value)}
                                       required
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button type="submit" className="fabulous-button" onClick={(e) => {
                                    e.preventDefault();
                                    void executeChange();
                                }}>Change Password
                                </button>
                            </div>
                        </form>
                    </div>
    );
}